import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import './AIScreening.css'; // Ensure this path is correct


const { Meta } = Card;

function AIScreening() {
    return (
        <div className="ai-screening-container">
            <h1>AI Screening</h1>
            <p>
                At JectHub, we understand the importance of assessing AI skills accurately to match students with the right opportunities. Our AI Screening feature is designed to help you evaluate your capabilities and prepare for real-world AI challenges.
            </p>
            <p>
                Whether you're looking to test your knowledge on AI concepts or to showcase your skills to potential employers, our platform offers a range of screening tools and resources:
            </p>
            <Row gutter={16}>
                <Col span={8}>
                    <Card
                        hoverable
                        cover={<img alt="AI Assessment" src="/images/assessment.jpg" />}
                    >
                        <Meta title="AI Assessments" description="Take comprehensive AI assessments to gauge your knowledge and skills in machine learning, neural networks, and more." />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        hoverable
                        cover={<img alt="Practice Challenges" src="/images/challenges.jpg" />}
                    >
                        <Meta title="Practice Challenges" description="Engage in practice challenges designed to simulate real-world AI problems and improve your problem-solving skills." />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        hoverable
                        cover={<img alt="Expert Feedback" src="/images/feedback.jpg" />}
                    >
                        <Meta title="Expert Feedback" description="Receive personalized feedback from industry experts to enhance your understanding and performance." />
                    </Card>
                </Col>
            </Row>
            <div className="call-to-action">
                <h2>Get Started with AI Screening</h2>
                <p>
                    Ready to test your AI skills? Sign up now and start exploring our AI Screening tools and resources. Whether you're preparing for interviews or looking to improve your skills, JectHub is here to support your journey.
                </p>
                <Button type="primary" href="/signup">
                    Sign Up
                </Button>
            </div>
        </div>
    );
}

export default AIScreening;
