import React, { useState } from 'react';
import { Layout, Menu, Button, Drawer, Carousel } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignInModal from './components/SignInModal';
import SignUpModal from './components/SignUpModal';
import SearchFilter from './components/SearchFilter';
import FooterComponent from './components/FooterComponent';
import MainContent from './components/MainContent';
import MenuItems from './components/MenuItems';
import './assets/styles/App.css';
import AIScreening from './pages/AIProducts/AIScreening';
import Newsroom from './pages/AboutJectHub/Newsroom'; // Import the Newsroom component

// Import your slider images
import slider1 from './images/slider1.jpg';
import slider2 from './images/slider2.jpg';
import slider3 from './images/slider3.jpg';
import slider4 from './images/slider4.jpg';
import slider5 from './images/slider5.jpg';

const { Header, Content } = Layout;

function App() {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
      <Router>
        <Layout className="layout">
          <Header className="header">
            <div className="logo">
              <img src="/images/logo.png" alt="JectHub Logo" className="logo-image" />
            </div>
            <Button
                className="mobile-menu-button"
                type="primary"
                onClick={showDrawer}
                icon={<MenuOutlined />}
            />
            <Menu
                theme="light"
                mode="horizontal"
                className="menu"
                style={{ backgroundColor: '#50b0e0' }}
            >
              <MenuItems closeDrawer={closeDrawer} />
            </Menu>
            <Drawer
                title="Menu"
                placement="right"
                closable={true}
                onClose={closeDrawer}
                open={drawerVisible}
            >
              <Menu
                  mode="vertical"
                  style={{ backgroundColor: '#50b0e0' }}
                  onClick={closeDrawer}
              >
                <MenuItems closeDrawer={closeDrawer} />
              </Menu>
            </Drawer>
          </Header>

          <Content style={{ padding: 0, margin: 0 }}>
            <div className="sliders-section">
              <Carousel autoplay className="carousel">
                {[slider1, slider2, slider3, slider4, slider5].map((slider, index) => (
                    <div key={index} className="carousel-slide">
                      <img src={slider} alt={`Slider ${index + 1}`} className="slider-image" />
                      <div className="slider-content">
                        {index === 0 && (
                            <>
                              <h2>Innovate with JectHub</h2>
                              <p>Join our community to turn ideas into reality.</p>
                            </>
                        )}
                        {index === 1 && (
                            <>
                              <h2>Challenge Yourself</h2>
                              <p>Participate in challenges to test and expand your skills.</p>
                            </>
                        )}
                        {index === 2 && (
                            <>
                              <h2>Collaborate on Projects</h2>
                              <p>Work on projects with peers to gain real-world experience.</p>
                            </>
                        )}
                        {index === 3 && (
                            <>
                              <h2>Learn from the Best</h2>
                              <p>Access mentorship from industry experts.</p>
                            </>
                        )}
                        {index === 4 && (
                            <>
                              <h2>Stay Updated</h2>
                              <p>Get the latest news and trends in AI and technology.</p>
                            </>
                        )}
                      </div>
                    </div>
                ))}
                {/* New Slide for Cybersecurity */}
                <div className="carousel-slide">
                  <img src="/images/slider3.jpg" alt="Cybersecurity" className="slider-image" />
                  <div className="slider-content">
                    <h2>Cybersecurity</h2>
                    <p>Learn how to protect systems and data from cyber threats.</p>
                  </div>
                </div>
                {/* New Slide for Data Science */}
                <div className="carousel-slide">
                  <img src="/images/slider4.jpg" alt="Data Science" className="slider-image" />
                  <div className="slider-content">
                    <h2>Data Science</h2>
                    <p>Discover the power of data and analytics to drive insights.</p>
                  </div>
                </div>
              </Carousel>
            </div>
            <div className="search-filter-container indigo darken-2 white-text center" style={{ padding: '5px 0' }}>
              <SearchFilter />
            </div>
            {/*<MainContent />*/}
            <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/news" element={<Newsroom />} />
            {/*<Route path="/Sign-In" element={<SignInModal />} />*/}
          </Routes>
          </Content>

          <FooterComponent />
          <Routes>
            <Route path="/signin" element={<SignInModal />} />
            <Route path="/signup" element={<SignUpModal />} />
            {/* Ensure this route is included */}
          </Routes>
        </Layout>
      </Router>
  );
}

export default App;
