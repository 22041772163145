import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Input, Button, Typography, Row, Col, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MailOutlined, UserOutlined, LockOutlined, GoogleOutlined, FacebookOutlined } from '@ant-design/icons';

const { Title } = Typography;

function SignUpModal() {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setVisible(true);
    }, []);

    const handleCancel = () => {
        setVisible(false);
        navigate('/');
    };

    const handleSignInRedirect = (e) => {
        e.preventDefault();
        navigate('/signin');
    };

    const handleSubmit = (values) => {
        // API request to register user
        axios.post('http://localhost:8080/api/users/register', values)
            .then(response => {
                console.log('User registered successfully:', response.data);
                navigate('/signin'); // Redirect to sign-in page after successful registration
            })
            .catch(error => {
                console.error('There was an error registering the user:', error);
            });
    };

    return (
        <Modal
            title=""
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={700}
            style={{ minHeight: '350px' }}
        >
            <Row style={{ height: '100%' }}>
                <Col
                    xs={0}
                    sm={12}
                    style={{
                        backgroundImage: 'url(/images/jecthublogin.jpeg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '350px',
                    }}
                >
                </Col>

                <Col xs={24} sm={12}>
                    <Form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            padding: '0 20px',
                        }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        validateMessages={{ required: '${label} is required!', types: { email: 'Not a valid email!' } }}
                        onFinish={handleSubmit} // Correctly add the onFinish attribute here
                    >
                        <div style={{
                            backgroundColor: '#1890ff',
                            padding: '10px',
                            width: '100%',
                            borderRadius: '4px',
                            marginBottom: '10px'
                        }}>
                            <Title level={4} style={{ color: '#fff', textAlign: 'center', margin: 0 }}>
                                Sign Up
                            </Title>
                        </div>

                        <Form.Item
                            label="EMAIL"
                            name="email"
                            rules={[{ required: true, type: 'email', message: 'Please enter a valid email!' }]}
                            style={{ marginBottom: '8px', textTransform: 'uppercase' }}
                        >
                            <Input
                                prefix={
                                    <Tooltip title="Email Address">
                                        <MailOutlined style={{ marginRight: 8 }} />
                                    </Tooltip>
                                }
                                placeholder="Enter your email address"
                                style={{ width: '100%', transition: 'background-color 0.3s', fontSize: '12px' }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
                            />
                        </Form.Item>

                        <Form.Item
                            label="USERNAME"
                            name="username"
                            rules={[{ required: true, message: 'Please enter your username!' }]}
                            style={{ marginBottom: '8px', textTransform: 'uppercase' }}
                        >
                            <Input
                                prefix={
                                    <Tooltip title="Username">
                                        <UserOutlined style={{ marginRight: 8 }} />
                                    </Tooltip>
                                }
                                placeholder="Choose a username"
                                style={{ width: '100%', transition: 'background-color 0.3s', fontSize: '12px' }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
                            />
                        </Form.Item>

                        <Form.Item
                            label="PASSWORD"
                            name="password"
                            rules={[{ required: true, message: 'Please enter your password!' }]}
                            style={{ marginBottom: '8px', textTransform: 'uppercase' }}
                        >
                            <Input.Password
                                prefix={
                                    <Tooltip title="Password">
                                        <LockOutlined style={{ marginRight: 8 }} />
                                    </Tooltip>
                                }
                                placeholder="Create a password"
                                style={{ width: '100%', transition: 'background-color 0.3s', fontSize: '12px' }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Create Account
                            </Button>
                        </Form.Item>

                        <div style={{ textAlign: 'center', margin: '16px 0' }}>
                            <hr style={{ border: '0', borderTop: '1px solid #dcdcdc', margin: '0' }} />
                            <span style={{
                                position: 'relative',
                                top: '-0.8em',
                                background: '#fff',
                                padding: '0 10px',
                                fontSize: '14px',
                                color: '#888'
                            }}>OR</span>
                        </div>

                        <Form.Item>
                            <Row gutter={16} style={{ marginBottom: '16px' }}>
                                <Col span={12}>
                                    <Button
                                        type="default"
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#4285F4',
                                            borderColor: '#4285F4',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '11px',
                                            padding: '4px 7px',
                                        }}
                                    >
                                        <GoogleOutlined style={{ marginRight: 7 }} />
                                        Sign up with Google
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        type="default"
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#4267B2',
                                            borderColor: '#4267B2',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '11px',
                                            padding: '4px 7px',
                                        }}
                                    >
                                        <FacebookOutlined style={{ marginRight: 7 }} />
                                        Sign up with Facebook
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item style={{ textAlign: 'center' }}>
                            <span>
                                Already have an account?{' '}
                                <a
                                    href="#"
                                    onClick={handleSignInRedirect}
                                    style={{ color: '#1890ff' }}
                                >
                                    Sign In
                                </a>
                            </span>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
}

export default SignUpModal;
