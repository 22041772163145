import React from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SimpleSearch = () => {
    return (
        <div
            className="search-bar-container"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50%',  // Reduced width
                margin: '0 auto',
                paddingTop: '15px',// Center the container
            }}
        >
            <Input
                className="text-group-left"
                placeholder="Search study resources"
                name="notebank_qs"
                maxLength={250}
                style={{
                    borderRadius: '4px 0 0 4px',
                    height: '40px',  // Increased height
                }}
            />
            <Button
                id="btn-notebank-search"
                className="text-group-icon-right"
                style={{
                    borderRadius: '0 4px 4px 0',
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px',  // Match height of Input
                }}
                icon={<SearchOutlined />}
            />
        </div>
    );
};

export default SimpleSearch;
