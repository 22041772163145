// src/components/MainContent.js
import React from 'react';
import { Row, Col, Card } from 'antd';
import './MainContent.css'; // Create and style this CSS file as needed

const { Meta } = Card;

const MainContent = () => (
    <div className="main-content">
        <Row gutter={16}>
            <Col span={24}>
                <div className="featured-subjects">
                    <h2>JectHub Features</h2>
                    <p>
                        At JectHub, our mission is to be the leading platform in Africa for advancing knowledge in Artificial Intelligence (AI) & Machine Learning, Cybersecurity, and Data Science. Explore our featured subjects, meticulously designed to help you master these cutting-edge technologies and stay ahead in the tech industry.
                    </p>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Card
                                hoverable
                                cover={<img alt="AI & Machine Learning" src="/images/ai-ml.jpeg" />}
                            >
                                <Meta title="AI & Machine Learning" description="Discover the fundamentals of AI and Machine Learning, from algorithms and data processing to practical applications. Enhance your skills to excel in the field of AI." />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card
                                hoverable
                                cover={<img alt="Cybersecurity" src="/images/cybersecurity.jpeg" />}
                            >
                                <Meta title="Cybersecurity" description="Master the principles of cybersecurity, including protecting systems and networks from cyber threats. Gain expertise in securing digital assets and combating cyber attacks." />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card
                                hoverable
                                cover={<img alt="Data Science" src="/images/data-science.jpeg" />}
                            >
                                <Meta title="Data Science" description="Explore data science techniques, from data analysis and statistics to extracting actionable insights from large data sets. Learn how to leverage data for informed decision-making." />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={24}>
                <div className="qa-tutoring">
                    <h2>How JectHub's Q&A Tutoring Works</h2>
                    <p>
                        At JectHub, we offer a cutting-edge Q&A Tutoring experience designed to support students in mastering Artificial Intelligence (AI) and related technologies. Here’s how it works:
                    </p>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Card title="Expert Guidance" bordered={false}>
                                Connect with AI experts and experienced tutors who provide personalized assistance on your projects and coursework. Our tutors are skilled in various AI domains, ensuring you get relevant and up-to-date insights.
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card title="Interactive Sessions" bordered={false}>
                                Engage in live, interactive Q&A sessions where you can ask questions, seek clarifications, and receive feedback on your work. Our platform facilitates real-time communication to help you tackle challenges efficiently.
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card title="Customized Learning" bordered={false}>
                                Whether you’re working on a specific AI project or need help with general concepts, our Q&A Tutoring is tailored to your needs. Submit your queries and receive responses that address your individual learning goals.
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card title="Collaborative Environment" bordered={false}>
                                Benefit from a collaborative learning environment where you can interact with fellow students and experts. Share knowledge, discuss solutions, and enhance your understanding of complex AI topics.
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card title="Accessible Anytime, Anywhere" bordered={false}>
                                Access our Q&A Tutoring services from anywhere, at any time. Our online platform ensures that support is available whenever you need it, making learning flexible and convenient.
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card title="Real-World Applications" bordered={false}>
                                Gain practical insights into AI applications and industry best practices. Our tutoring sessions focus on real-world scenarios, helping you apply theoretical knowledge to practical problems.
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>

    </div>
);

export default MainContent;
