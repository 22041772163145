import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Typography, Row, Col, Tooltip, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MailOutlined, LockOutlined, GoogleOutlined, FacebookOutlined } from '@ant-design/icons';

const { Title } = Typography;

function SignInModal() {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setVisible(true);
    }, []);

    const handleCancel = () => {
        setVisible(false);
        navigate('/');
    };

    const handleSignUpRedirect = () => {
        navigate('/signup');
    };

    const handleSubmit = (values) => {
        // Handle sign-in form submission
        console.log('Sign-in form submitted:', values);
        // Add your sign-in logic here
    };

    return (
        <Modal
            title=""
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            width={700}  // Reduced width
            style={{ minHeight: '350px' }}  // Adjusted minHeight
        >
            <Row style={{ height: '100%' }}>
                <Col
                    xs={0}  // Hidden on extra small screens (mobile devices)
                    sm={12}  // Displayed on small screens and larger
                    style={{
                        backgroundImage: 'url(/images/jecthublogin.jpeg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '350px',  // Adjusted minHeight
                    }}
                >
                </Col>

                <Col xs={24} sm={12}>
                    <Form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            padding: '0 20px',  // Reduced padding
                        }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        validateMessages={{ required: '${label} is required!', types: { email: 'Not a valid email!' } }}
                        onFinish={handleSubmit}  // Add onFinish attribute for form submission
                    >
                        <div style={{
                            backgroundColor: '#1890ff',
                            padding: '10px',
                            width: '100%',
                            borderRadius: '4px',
                            marginBottom: '10px'
                        }}>
                            <Title level={4} style={{ color: '#fff', textAlign: 'center', margin: 0 }}>
                                Sign In
                            </Title>
                        </div>

                        <Form.Item
                            label="EMAIL"
                            name="email"
                            rules={[{ required: true, type: 'email', message: 'Please enter a valid email!' }]}
                            style={{ marginBottom: '8px', textTransform: 'uppercase' }}
                        >
                            <Input
                                prefix={
                                    <Tooltip title="Email Address">
                                        <MailOutlined style={{ marginRight: 8 }} />
                                    </Tooltip>
                                }
                                placeholder="Enter your email address"
                                style={{ width: '100%', transition: 'background-color 0.3s', fontSize: '12px' }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
                            />
                        </Form.Item>

                        <Form.Item
                            label="PASSWORD"
                            name="password"
                            rules={[{ required: true, message: 'Please enter your password!' }]}
                            style={{ marginBottom: '8px', textTransform: 'uppercase' }}
                        >
                            <Input.Password
                                prefix={
                                    <Tooltip title="Password">
                                        <LockOutlined style={{ marginRight: 8 }} />
                                    </Tooltip>
                                }
                                placeholder="Enter your password"
                                style={{ width: '100%', transition: 'background-color 0.3s', fontSize: '12px' }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
                            />
                        </Form.Item>

                        <Form.Item
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '16px',
                                padding: '0 10px',  // Add padding here
                            }}
                        >
                            <Checkbox>Stay signed in</Checkbox>
                            <a href="/" style={{ marginLeft: 'auto', paddingLeft: '15px' }}>Forgot password?</a>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Sign In
                            </Button>
                        </Form.Item>

                        <div style={{ textAlign: 'center', margin: '16px 0' }}>
                            <hr style={{ border: '0', borderTop: '1px solid #dcdcdc', margin: '0' }} />
                            <span style={{
                                position: 'relative',
                                top: '-0.8em',
                                background: '#fff',
                                padding: '0 10px',
                                fontSize: '14px',
                                color: '#888'
                            }}>OR</span>
                        </div>

                        <Form.Item>
                            <Row gutter={16} style={{ marginBottom: '16px' }}>
                                <Col span={12}>
                                    <Button
                                        type="default"
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#4285F4',
                                            borderColor: '#4285F4',
                                            color: '#fff',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            fontSize: '11px',
                                            padding: '4px 7px',
                                        }}
                                    >
                                        <GoogleOutlined style={{ marginRight: 7 }} />
                                        Sign in with Google
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        type="default"
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#4267B2',
                                            borderColor: '#4267B2',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '11px',
                                            padding: '4px 7px',
                                        }}
                                    >
                                        <FacebookOutlined style={{ marginRight: 7 }} />
                                        Sign in with Facebook
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item style={{ textAlign: 'center' }}>
                            <span>
                                Don't have an account?{' '}
                                <a
                                    href="#"
                                    onClick={handleSignUpRedirect}
                                    style={{ color: '#1890ff' }}
                                >
                                    Sign Up
                                </a>
                            </span>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
}

export default SignInModal;