import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const MenuItems = ({ closeDrawer }) => {
    return (
        <>
            <Menu.Item key="1">
                <Link to="/" className="menu-link">HOME</Link>
            </Menu.Item>

            <Menu.Item key="2">
                <Link to="/challenges" className="menu-link">CHALLENGES</Link>
            </Menu.Item>
            <Menu.Item key="3">
                <Link to="/projects" className="menu-link">PROJECTS</Link>
            </Menu.Item>
            <Menu.Item key="4">
                <Link to="/learning" className="menu-link">LEARNING</Link>
            </Menu.Item>
            <Menu.Item key="5">
                <Link to="/mentorship" className="menu-link">MENTORSHIP</Link>
            </Menu.Item>
            <Menu.Item key="6">
                <Link to="/community" className="menu-link">COMMUNITY</Link>
            </Menu.Item>
            <Menu.Item key="7">
                <Link to="/assessment" className="menu-link">ASSESSMENT</Link>
            </Menu.Item>

            <Menu.Item key="8">
                <Link to="/courses" className="menu-link">COURSES</Link>
            </Menu.Item>
            <Menu.Item key="9">
                <Link to="/news" className="menu-link">NEWS</Link>
            </Menu.Item>
            <Menu.Item key="10" style={{ padding: '0 0 0 8px' }}>
                <Link
                    to="/signin"
                    className="menu-link rounded-button"
                    style={{
                        backgroundColor: '#ff4eaa',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '10px',
                        display: 'inline-block',
                        textAlign: 'center',
                        textDecoration: 'none'
                    }}
                >
                    LOGIN
                </Link>
            </Menu.Item>
            <Menu.Item key="10" style={{ padding: '0 0 0 8px' }}>
                <Link
                    to="/signup"
                    className="menu-link rounded-button"
                    style={{
                        backgroundColor: '#1A237E',
                        color: 'white',
                        padding: '10px 16px',
                        borderRadius: '10px',
                        display: 'inline-block',
                        textAlign: 'center',
                        textDecoration: 'none'
                    }}
                >
                    SIGN UP
                </Link>
            </Menu.Item>
        </>
    );
};

export default MenuItems;
